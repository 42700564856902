// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Routing
import { withRouter, Switch, Route } from "react-router-dom";
// Vendor
import {
  updateAccessStatus,
  resetState,
  hideNotify
} from "vendor/application/disptach";
// Containers
import Landing from "views/Root/application/Routes/Landing/application";
import App from "views/Root/application/Routes/App/application";
import NotFound from "components/NotFound/application/404";
import TestChat from "views/Root/application/Routes/TestChat/application";
import HelpChat from "views/Root/application/Routes/HelpChat/application";
// Components
import Notify from "components/Notify";

class Root extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //switch login/ register
      isPageLogin: true
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.redirect();
    }, 100);
  }

  // method that reloads the component when the current project has been changed
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    // we SET the value from isNotify to false
    var notify = nextProps.data.notify;
    if (notify.isNotify) {
      var data = {
        message: notify.message,
        severity: notify.severity,
        type: notify.type,
        item: notify.item
      };
      this.props.dispatch(hideNotify(data));
    }
  };

  // redirect app route
  redirect() {
    const { history, location } = this.props;
    // if isAccess value NOT EXIST we'll reset de app and go back to landing
    if (
      !this.props.data.access.isAccess &&
      !this.props.data.access.userId &&
      location.pathname !== "/signin/verify" &&
      location.pathname !== "/landing/verify" &&
      location.pathname !== "/test_chat" &&
      location.pathname !== "/password/forgot" &&
      location.pathname !== "/password/recover" &&
      location.pathname !== "/contacto" &&
      location.pathname !== "/landing/signin"
    ) {
      this.props.dispatch(resetState());
      history.push("/landing/login");
    }
  }

  // update access data state
  handleAccess = (access, userId) => {
    var data = { ...access, isAccess: true, userId: userId };
    this.props.dispatch(updateAccessStatus(data));
  };

  // shutDown app
  shutDown = (e) => {
    e.preventDefault();
    const { history } = this.props;

    this.props.dispatch(resetState());
    history.push("/landing/login");
  };

  render() {
    const { notify } = this.props.data;
    return (
      <React.Fragment>
        <Notify notify={notify} />
        <Switch>
          <Route path={["/landing", "/password"]}>
            <Landing state={this.props.data} handleAccess={this.handleAccess} />
          </Route>
          <Route path="/test_chat" exact>
            <TestChat />
          </Route>
          <Route path="/help_chat" exact>
            <HelpChat />
          </Route>
          <Route path="/">
            <App shutDown={this.shutDown} />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state
  };
};

const connect_root = connect(mapStateToProps)(Root);

export default withRouter(connect_root);
